<template>
	<div>
		<ListChapterSketelon v-if="!selectingParcours" />
		<ListChapters v-else :parcours="selectingParcours" :fromRedaction="true" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListChapterSketelon from '@/components/skeletons/ListChapterSketelon.vue';
import ListChapters from '@/components/dashboard/profile/chapter/ListChapters.vue';

export default {
	name: 'RedactionParcoursDetail',
	components: { ListChapterSketelon, ListChapters },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	data() {
		return {
			selectingParcours: null,
		};
	},
	mounted() {
		if (this.formationProgress != null) this.selectParcoursToRender();
	},
	watch: {
		formationProgress() {
			this.selectParcoursToRender();
		},
		'$route.params.parcoursId'(newId, oldId) {
			if (newId === oldId) return;
			else this.selectParcoursToRender();
		},
	},
	methods: {
		selectParcoursToRender() {
			const { parcoursId } = this.$router.currentRoute.params;
			const parcoursItem = this.formationProgress.config.parcours_list.find((parcoursItem) => {
				return parcoursItem.parcours_id._id === parcoursId;
			});
			if (parcoursItem == null) return;
			this.selectingParcours = parcoursItem.parcours_id;
		},
	},
};
</script>
